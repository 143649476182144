@media only screen and (max-width: 768px) {
  .toolbar-height-second {
    height: 75px !important;
  }
  .main-content {
    padding: 40px 0px !important;
    position: relative;
    top: 80px !important;
  }
  mat-sidenav {
    top: 108px !important;
  }
  .tab {
    width: -webkit-fill-available !important;
  }
  .tab-con {
    justify-content: center;
  }
  .tab-nav a {
    width: auto !important;
    height: auto !important;
    display: flex;
    align-items: center;
    text-align: center !important;
  }
  .tab-nav a img {
    width: 30px;
    height: 30px;
    display: none;
  }
  .right-container {
    position: fixed;
    top: 75px;
    z-index: 2;
    background: #1e272a;
    padding: 10px;
    margin-right: 0px !important;
  }
  .left-container {
    justify-content: center;
  }
  .text-header {
    font-size: 14px !important;
    font-weight: normal !important;
  }
}

@media (min-width: 768px) and (max-width: 1070px) {
  .tab {
    width: 40% !important;
  }
}
