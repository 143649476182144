@use "@angular/material" as mat;

:root,
:host {
  --mdc-plain-tooltip-container-color: #333e41;
  --mad-plain-tooltip-supporting-text-line-height: 2;

  --mat-toolbar-container-text-color: #fff;
  --mat-app-text-color: #fff;
  --mat-app-background-color: #1e272a;

  --mat-toolbar-container-background-color: #1e272a;
  --mat-toolbar-container-text-color: #fff;

  --mat-menu-container-color: #242f32;
  --mat-menu-item-label-text-line-height: 1rem;
  --mat-menu-item-label-text-size: 0.9rem;
  --mat-menu-item-label-text-weight: normal;
  --mat-menu-item-label-text-color: #fff;
  --mat-toolbar-standard-height: 4rem;

  --mat-toolbar-title-text-size: 1rem;
  --mat-toolbar-title-text-weight: 300;
  --mat-sidenav-content-text-color: #fff;
  --mat-sidenav-container-background-color: #1e272a;
  --mat-sidenav-content-background-color: #242f32;
  --mat-icon-color: #fff;
  --mdc-list-list-item-label-text-color: #fff;

  --mdc-list-list-item-hover-label-text-color: #fff;
  --mdc-list-list-item-focus-label-text-color: #fff;
  --mdc-list-list-item-hover-state-layer-color: #201a1b;
  --mdc-list-list-item-hover-state-layer-opacity: 0.2;
  --mdc-list-list-item-focus-state-layer-color: #201a1b;
  --mdc-list-list-item-focus-state-layer-opacity: 0;
  --mat-list-active-indicator-shape: 0px;

  --mdc-text-button-label-text-color: #fff;
  --mat-datepicker-calendar-container-background-color: #242f32;
  --mat-datepicker-calendar-container-text-color: #fff;
  --mat-datepicker-calendar-date-text-color: #fff;
  --mat-datepicker-calendar-navigation-button-icon-color: #fff;
  --mat-datepicker-calendar-date-disabled-state-text-color: #fff;
  --mat-datepicker-calendar-date-today-outline-color: #e3ca41;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: transparent;
  --mat-datepicker-calendar-date-hover-state-background-color: transparent;
  --mat-datepicker-calendar-date-selected-state-background-color: #e3ca41;
  --mdc-icon-button-icon-color: #fff;
  --mat-datepicker-toggle-active-state-icon-color: #e3ca41;

  --mat-datepicker-calendar-date-selected-state-background-color: #e3ca41;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #e3ca41;
  --mat-datepicker-calendar-date-selected-state-text-color: #000;

  --mdc-dialog-container-color: rgb(51, 62, 65) !important;
  --mat-tree-container-background-color: rgb(51, 62, 65) !important;

  --mdc-checkbox-disabled-unselected-icon-color: #eee;
  --mdc-checkbox-selected-checkmark-color: #242424;
  --mdc-checkbox-selected-focus-icon-color: #e3ca41;
  --mdc-checkbox-selected-hover-icon-color: #e3ca41;
  --mdc-checkbox-selected-icon-color: #e3ca41;
  --mdc-checkbox-unselected-focus-icon-color: #eee;
  --mdc-checkbox-unselected-hover-icon-color: #eee;
  --mdc-checkbox-unselected-icon-color: #eee;

  --mat-tree-node-text-size: 1rem;
  --mat-tree-node-text-weight: 300;
  --mdc-icon-button-state-layer-size: 14px;
  --mdc-checkbox-state-layer-size: 20px;
  --mat-tree-node-min-height: 24px;

  --mat-form-field-error-text-color: #e3ca41;

  --mat-dialog-actions-padding: 20px;
  --mat-dialog-actions-alignment: end;
  --mat-dialog-headline-padding: 0px 20px;
  --mdc-dialog-subhead-line-height: 0px;

  --mat-full-pseudo-checkbox-selected-icon-color: red;

  --mdc-elevated-card-container-color: rgb(30, 39, 42);

  --mdc-filled-text-field-caret-color: white;
  --mdc-filled-text-field-container-color: rgb(51, 62, 65);
  --mdc-filled-text-field-focus-label-text-color: #fff;
  --mat-option-selected-state-layer-color: rgb(51, 62, 65);
  --mat-full-pseudo-checkbox-selected-icon-color: #fff;
  --mat-select-placeholder-text-color: #fff;
  --mdc-filled-text-field-container-color: rgb(51, 62, 65);
  --mat-select-panel-background-color: rgb(51, 62, 65);
  --mat-option-selected-state-label-text-color: #e3ca41;
  --mdc-filled-text-field-focus-label-text-color: #e3ca41;
  --mat-select-focused-arrow-color: #e3ca41;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #e3ca41 !important;
  --mat-stepper-header-selected-state-icon-background-color: #e3ca41 !important;
  --mdc-filled-text-field-caret-color: #e3ca41 !important;
  --mdc-filled-text-field-focus-active-indicator-color: #e3ca41 !important ;
  --mdc-outlined-text-field-focus-outline-color: #e3ca41;
}

html,
body {
  height: 100%;
  font-size: 1rem;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-weight: 300;

  scrollbar-face-color: #ff8c00;
  scrollbar-track-color: #fff8dc;
  scrollbar-arrow-color: #ffffff;
  scrollbar-highlight-color: #fff8dc;
  scrollbar-shadow-color: #d2691e;
  scrollbar-darkshadow-color: #8b0000;
}

body {
  margin: 0;
  background-color: #1e272a;
}
$theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
    ),
    typography: (
      brand-family: "Roboto",
      bold-weight: 900,
    ),
    density: (
      scale: -5,
    ),
  )
);

.mat-mdc-menu-item:hover {
  color: #f0d415;
}

.menu-active span,
.menu-active img {
  color: #e3ca41 !important;
  font-weight: 700 !important;
}

.input-bg {
  background-color: #1e272a;
  margin-right: 0px;
  padding: 12px;
  border-radius: 10px;
  color: #fff;
  box-shadow: none;
  border: 1px solid rgb(118, 118, 118);
  font-size: 1rem;
  padding-right: 0px;
  font-weight: 500;
  width: 11vw;
}

.doughnut-container {
  background-color: rgb(51, 62, 65) !important;
  padding: 20px;
  margin-top: 40px;
}
.doughnut-background-white {
  background-color: #ffffff !important;
  color: #1e272a !important;
}

.doughnut-background-black {
  background-color: rgb(51, 62, 65) !important;
  color: #ffffff !important;
}

.doughnut-title {
  font-size: 1.375rem;
  font-weight: 700;
}
.setting-image {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.doughnut-row {
  display: flex;
  gap: 15px;
}
.doughnut-sub-title {
  font-size: 0.875rem;
  font-weight: 300;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.doughnut-chart-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 50px;
  padding: 0px 300px;
}
mat-card-header {
  position: absolute;
  right: 15px;
}
mat-card {
  background-color: rgb(30, 39, 42);
  border-radius: 6px;
  box-sizing: border-box;
  color: rgb(240, 212, 21);
  max-width: 100% !important;
  padding: 15px;
  position: relative;
  width: 450px !important;
  border: 1px solid rgb(240, 212, 21) !important;
  text-align: center;
  justify-content: right;
  gap: 10px;
}
.mat-mdc-card-content {
  font-size: 1.25rem !important;
  font-weight: 300 !important;
  padding: 20px 10px !important;
}
.mat-mdc-menu-content {
  color: rgb(240, 212, 21);
}
.progress {
  font-size: 0.825rem !important;
  font-weight: 300 !important;
  color: rgb(51, 62, 65) !important;
}
.mat-mdc-card-actions {
  justify-content: end;
  gap: 10px;
}
mat-card-actions button.next,
mat-card-actions button.prev,
.mat-mdc-button:not(:disabled) {
  background-color: rgb(240, 212, 21);
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 8px 12px;
  border-radius: 2px;
  border: none;
  font-size: 0.825rem !important;
  cursor: pointer;
}
mat-card-actions button.next mat-icon,
mat-card-actions button.prev mat-icon,
.mdc-button__label {
  color: rgb(51, 62, 65) !important;
  padding: 0px;
  height: 20px;
  width: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step.arrow.mat-menu-below:not(
    .horizontal
  ):after {
  border-bottom-color: #f0d415 !important;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step.arrow.mat-menu-after.horizontal:after {
  border-left-color: transparent;
  border-right-color: #f0d415 !important;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step.arrow.mat-menu-above:not(
    .horizontal
  ):after {
  border-top-color: #f0d415 !important;
  border-bottom-color: transparent;
}
.header-group .close {
  background: none;
  border: 0px;
  position: relative;
  top: -25px;
  left: 30px;
}
.select-button {
  background-color: #f0d415 !important;
  color: rgb(51, 62, 65) !important;
  font-size: 0.813rem;
  width: 100px;
  border-radius: unset !important;
  font-weight: 700 !important;
}

.mdc-text-field--filled {
  background-color: #1e272a !important;
}

.mdc-text-field--filled::after {
  background-color: #1e272a !important;
}
.no-hospital {
  display: flex;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
#no-data {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
}

.fof p {
  padding: 10px;
  font-size: 1rem;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
.navigate-button-align {
  cursor: pointer;
  border: unset;
  margin-top: 5px;
  width: 150px;
  font-size: 0.95rem;
  padding: 10px;
  font-weight: 300;
}

.information-message {
  color: #fff;
  text-align: center;
  padding: 16px;
}
.account-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.account-container > * {
  width: calc(100% / 4);
  padding: 1rem;
}
.account-container .input-block label {
  display: flex;
  font-size: 1rem;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.account-container .input-block input,
.account-container .input-block mat-select,
.account-container .input-block mat-radio-group {
  border: 1px solid #fff;
  background-color: rgb(51, 62, 65);
  color: #fff;
  font-size: 1rem;
  height: 2.5rem;
  width: -webkit-fill-available;
  width: fill-available;
  width: -moz-available;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
}

.submit-block,
.cancel-block,
.disbale-block {
  background-color: rgb(51, 62, 65);
  color: #fff;
  font-size: 1rem;
  border: unset;
  border: 1px solid #ffffff;
  padding: 1rem 2.5rem;
  border-radius: 2rem;
  cursor: pointer;
}
.disbale-block {
  color: rgb(154, 154, 154);
  pointer-events: none;
}
.cancel-block {
  margin-left: 2rem;
}

.back-button-block {
  display: flex;
  float: right;
  background-color: rgb(51, 62, 65);
  color: #ced4da;
  font-size: 1rem;
  border: unset;
  border: 1px solid #ffffff;
  padding: 8px 50px 0px 20px;
  border-radius: 2rem;
  cursor: pointer;
}
.admin-wrapper .material-icons {
  padding-top: 5px;
  padding-right: 5px;
  font-size: 18px;
  cursor: pointer;
}

.material-icons:hover {
  color: #e3ca41;
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333e41;
  --mdc-snackbar-supporting-text-color: white;
}
.error-display {
  font-size: 1.25rem;
  color: #e3ca41;
  display: flex;
  justify-content: center;
}

a,
button,
.mat-button {
  cursor: pointer !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

scrollbar-width {
  width: 5px;
  height: 5px;
}

scrollbar-thumb {
  background-color: #888;
}

scrollbar-track {
  background-color: #f1f1f1;
}

scrollbar-thumb:hover {
  background-color: #555;
}

::-ms-scrollbar {
  width: 5px;
}

::-ms-scrollbar-thumb {
  background-color: #888;
}

::-ms-scrollbar-track {
  background-color: #f1f1f1;
}
